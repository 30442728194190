import styled from "styled-components";
import { colors } from "../../../../utils/stylesUtil";

export const Wrapper = styled.header`
  text-align: center;
  font-size: 1.2em;

  /* > div {
    display: flex;
    flex-direction: column;
    align-items: end;
    font-size: 1rem;
    color: white;
  } */
`;

export const WrapperCredits = styled.div`
  display: flex;
  justify-content: space-between;
  background-color: ${colors.blue};
  padding: 20px;

  > span {
    color: white;
    font-weight: bold;
  }
`;
export const WrapperNextRenewal = styled.div`
  display: flex;
  justify-content: space-between;
  background-color: ${colors.mediumGrey};
  padding: 7px;

  > span {
    font-size: 0.75em;

    &:last-child {
      font-weight: bold;
    }
  }
`;

export const CloseButton = styled.button`
  background-color: transparent;
  border: none;
  font-size: 1em;
  cursor: pointer;
  color: white;
`;
