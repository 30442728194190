import {
  getRoleInfoUrl,
  getQuerieUrl,
  getChatIdUrl,
  getUserInfoUrl,
  getUserCreditsUrl,
  getDeleteChatUrl,
  getParameterFrequencyUrl,
} from "../resolvers/resolvers";
import {
  handleResponse,
  handleError,
  getRequestOptions,
} from "../utils/apiUtils";

async function getRoleInfo(roleId) {
  const requestOptions = getRequestOptions("GET");

  return await fetch(getRoleInfoUrl(roleId), requestOptions).then(
    handleResponse,
    handleError,
  );
}

async function getChatId(assistantId) {
  const requestOptions = getRequestOptions("POST");

  return await fetch(getChatIdUrl(assistantId), requestOptions).then(
    handleResponse,
    handleError,
  );
}

async function sendQuerie(data, assitantId, chatId) {
  const requestOptions = getRequestOptions("POST", data);

  return await fetch(getQuerieUrl(assitantId, chatId), requestOptions).then(
    handleResponse,
    handleError,
  );
}

async function getUserInfo(userId) {
  const requestOptions = getRequestOptions("GET");

  return await fetch(getUserInfoUrl(userId), requestOptions).then(
    handleResponse,
    handleError,
  );
}

async function getUserCredits(userId) {
  const requestOptions = getRequestOptions("GET");

  return await fetch(getUserCreditsUrl(userId), requestOptions).then(
    handleResponse,
    handleError,
  );
}

async function deleteChat(assitantId, chatId) {
  const requestOptions = getRequestOptions("DELETE");

  return await fetch(getDeleteChatUrl(assitantId, chatId), requestOptions).then(
    handleResponse,
    handleError,
  );
}

async function getParameterFrequency() {
  const requestOptions = getRequestOptions("GET");

  return await fetch(getParameterFrequencyUrl(), requestOptions).then(
    handleResponse,
    handleError,
  );
}

const service = {
  getRoleInfo,
  getChatId,
  sendQuerie,
  getUserInfo,
  getUserCredits,
  deleteChat,
  getParameterFrequency,
};

export default service;
