// DEPENDENCIES
import { useEffect, useRef } from "react";

// CONTEXT
import { useLoading } from "../../../../context/LoadingContext";
import { useChat } from "../../../../context/ChatContext";

// STYLED
import {
  Spinner,
  Wrapper,
  WrapperMessage,
  WrapperSpinner,
  RobotIcon,
} from "./Messages.styled";

// FUNCTION
const Messages = () => {
  const { isLoading } = useLoading();
  const { messages, userInfo, setMessages, chatId, paramsInfo } = useChat();

  const messagesEndRef = useRef(null);

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  const isAccountBlocked = userInfo.blocked;

  const firstContext = paramsInfo.context?.split(" ")[0];

  useEffect(() => {
    scrollToBottom();
  }, [messages, userInfo?.credits]);

  useEffect(() => {
    if (isAccountBlocked) {
      setMessages([
        {
          content: "Tu cuenta ha sido bloqueada, contacte con un administrador",
          role: "error",
        },
      ]);
    } else if (isAccountBlocked === 0 && !userInfo.credits) {
      setMessages((prevState) => [
        ...prevState,
        {
          content: "No te quedan suficientes créditos",
          role: "error",
        },
      ]);
    } else if (chatId) {
      if (userInfo?.credits) {
        setMessages([
          {
            content: `Hola, ${userInfo.nombre}. Como ${paramsInfo.roleId} veo que estás en la aplicación ${firstContext}. ¿En qué te puedo ayudar?`,
            role: "assistant",
          },
        ]);
      }
    } else {
      setMessages([]);
    }
  }, [chatId, isAccountBlocked]);

  return (
    <Wrapper>
      {messages.map((message, i) => (
        <WrapperMessage role={message.role} key={i}>
          <div role={message.role}>
            {message.role !== "user" && <RobotIcon />}
            <span>{message.content}</span>
          </div>
        </WrapperMessage>
      ))}
      {isLoading && (
        <WrapperSpinner>
          <Spinner />
        </WrapperSpinner>
      )}
      <div ref={messagesEndRef} />
    </Wrapper>
  );
};

export default Messages;
