export async function handleResponse(response) {
  if (response.ok) {
    const contentType = response.headers.get("content-type");
    return contentType && contentType.includes("application/json")
      ? response.json()
      : response.text();
  }

  const errorText = await response.text();

  const errorMessages = {
    400: errorText,
    401: errorText,
    403: errorText,
    404: errorText,
    409: errorText,
    429: errorText,
    503: "Sorry we are doing some maintenance. Please check back soon.",
    500: "Internal server error",
  };

  if (errorMessages[response.status]) {
    const error = new Error(errorMessages[response.status]);
    if (response.status === 401) error.status = response.status;
    throw error;
  }

  throw new Error("Network response was not ok.");
}

export function handleError(error) {
  console.error("API call failed. " + error);
  throw error;
}

export const getRequestOptions = (method, body) => ({
  method: method,
  headers: {
    "Content-Type": "application/json; charset=UTF-8",
  },
  body: body ? JSON.stringify(body) : undefined,
});
