// DEPENDENCIES
import { createContext, useContext, useEffect, useState, useMemo } from "react";

// CONTEXT
import { useLoading } from "../context/LoadingContext";

// SERVICES
import Service from "../services/services";

// UTILS
import { formatCreditsRenewal } from "../utils/dateUtil";

// CREATE CONTEXT
const ChatContext = createContext();

// FUNCTION
export const ChatProvider = ({
  children,
  initialMessages,
  initialUserInfo,
}) => {
  const { setLoading } = useLoading();

  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);

  const userId = urlParams.get("user");
  const roleId = urlParams.get("role");
  const context = urlParams.get("context");

  const [messages, setMessages] = useState(initialMessages || []);
  const [userInfo, setUserInfo] = useState(initialUserInfo || {});
  const [chatId, setChatId] = useState("");
  const [assitantId, setAssitantId] = useState("");
  const [nextCreditsRenewal, setNextCreditsRenewal] = useState("");
  const [errorScreenDisplayed, setErrorScreenDisplayed] = useState(false);

  useEffect(() => {
    if (!userId || !roleId || !context || errorScreenDisplayed) {
      return setErrorScreenDisplayed(true);
    }

    const fetchAssistantData = async () => {
      try {
        const { assistant_id } = await Service.getRoleInfo(roleId);
        setAssitantId(assistant_id);
      } catch (error) {
        setErrorScreenDisplayed(true);
        console.error("Request error", error);
      }
    };

    fetchAssistantData();
  }, []);

  useEffect(() => {
    if (errorScreenDisplayed) return;

    const fetchData = async () => {
      try {
        setLoading(true);
        const responseUserInfo = await Service.getUserInfo(userId);
        setUserInfo(responseUserInfo);
        const responseFrequency = await Service.getParameterFrequency();
        setNextCreditsRenewal(
          formatCreditsRenewal(
            responseUserInfo.last_credits_renewal,
            responseFrequency.value,
          ),
        );

        if (!responseUserInfo.blocked && responseUserInfo.credits) {
          const responseChatId = await Service.getChatId(assitantId);
          setChatId(responseChatId?.chatId);
        }

        setLoading(false);
      } catch (error) {
        setLoading(false);
        console.error("Request error:", error);
      }
    };

    if (assitantId) {
      fetchData();
    }
  }, [assitantId]);

  return (
    <ChatContext.Provider
      value={useMemo(
        () => ({
          messages,
          setMessages,
          userInfo,
          setUserInfo,
          chatId,
          setChatId,
          nextCreditsRenewal,
          assitantId,
          errorScreenDisplayed,
          paramsInfo: { userId, roleId, context },
        }),
        [
          messages,
          userInfo,
          chatId,
          nextCreditsRenewal,
          assitantId,
          errorScreenDisplayed,
        ],
      )}
    >
      {children}
    </ChatContext.Provider>
  );
};

export const useChat = () => useContext(ChatContext);
