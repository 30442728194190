// DEPENDENCIES
import { useEffect, useRef } from "react";

// CONTEXT
import { useLoading } from "../../../../context/LoadingContext";
import { useChat } from "../../../../context/ChatContext";

// HOOKS
import useChatMessages from "../../../../hooks/useChatMessages";

// STYLED
import { Wrapper } from "./Footer.styled";

// FUNCTION
const Footer = () => {
  const { isLoading } = useLoading();
  const { messages, userInfo, setUserInfo, chatId } = useChat();
  const { querie, setQuerie, sendMessage } = useChatMessages(
    chatId,
    setUserInfo,
  );

  const inputRef = useRef(null);

  useEffect(() => {
    if (!isLoading) {
      inputRef.current?.focus();
    }
  }, [messages, isLoading]);

  const handleChange = (e) => {
    setQuerie((prevState) => ({ ...prevState, message: e.target.value }));
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter" && querie.message && userInfo.credits) {
      sendMessage();
    }
  };

  const isAccountBlocked = userInfo.blocked;

  return (
    <Wrapper>
      <input
        type="text"
        value={querie.message}
        onChange={handleChange}
        onKeyDown={handleKeyPress}
        ref={inputRef}
        disabled={isLoading || !userInfo?.credits || isAccountBlocked}
        placeholder="Escribe una pregunta..."
      />
      <button
        disabled={
          !querie.message || isLoading || !userInfo?.credits || isAccountBlocked
        }
        onClick={() => userInfo?.credits && sendMessage()}
      >
        ➤
      </button>
    </Wrapper>
  );
};

export default Footer;
