// CONTEXT
import { useChat } from "../../../../context/ChatContext";

// SERVICES
import Service from "../../../../services/services";

// STYLED
import {
  Wrapper,
  CloseButton,
  WrapperCredits,
  WrapperNextRenewal,
} from "./Header.styled";

// FUNCTION
const Header = () => {
  const { userInfo, setChatId, chatId, nextCreditsRenewal, assitantId } =
    useChat();

  const handleCloseChat = async () => {
    try {
      setChatId("");
      await Service.deleteChat(assitantId, chatId);
    } catch (error) {
      console.error("Error removing the chat:", error);
    }
  };

  const isAccountBlocked = userInfo.blocked;

  return (
    <Wrapper>
      <WrapperCredits>
        <span>Créditos: {isAccountBlocked ? "-" : userInfo?.credits}</span>
        <CloseButton onClick={handleCloseChat}>X</CloseButton>
      </WrapperCredits>
      <WrapperNextRenewal>
        <span>Siguiente renovación de créditos:</span>
        <span>{nextCreditsRenewal}</span>
      </WrapperNextRenewal>
    </Wrapper>
  );
};

export default Header;
