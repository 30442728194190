// DEPENDENCIES
import { useState, useCallback, useMemo } from "react";

// CONTEXT
import { useLoading } from "../context/LoadingContext";
import { useChat } from "../context/ChatContext";

// SERVICES
import Service from "../services/services";

// FUNCTION
const useMessages = (chatId, setUserInfo) => {
  const { setLoading } = useLoading();
  const { setMessages, assitantId } = useChat();

  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);

  const userId = urlParams.get("user");

  const initialQuerie = useMemo(
    () => ({
      message: "",
      role: "assistant",
      index: -1,
      usuarioId: userId,
    }),
    [],
  );

  const [querie, setQuerie] = useState(initialQuerie);

  const sendMessage = useCallback(async () => {
    try {
      setMessages((prevMessages) => [
        ...prevMessages,
        { role: "user", content: querie.message },
      ]);

      setQuerie(initialQuerie);
      setLoading(true);
      const responseMessages = await Service.sendQuerie(
        querie,
        assitantId,
        chatId,
      );
      setLoading(false);
      if (responseMessages?.[0]?.content) {
        setMessages((prevMessages) => [
          ...prevMessages,
          { role: "assistant", content: responseMessages[0]?.content },
        ]);
      }

      const responseUserCredits = await Service.getUserCredits(userId);

      setUserInfo((prevState) => ({
        ...prevState,
        credits: responseUserCredits?.credits,
      }));
    } catch (error) {
      setLoading(false);
      console.error("Error sending message:", error);
    }
  }, [chatId, querie, initialQuerie, setUserInfo]);

  return {
    querie,
    setQuerie,
    sendMessage,
    setMessages,
  };
};

export default useMessages;
