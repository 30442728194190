// CONTEXT
import { useChat } from "../../context/ChatContext";

// COMPONENTS
import Header from "./components/header/Header";
import Messages from "./components/messages/Messages";
import Footer from "./components/footer/Footer";

// STYLED
import { ChatContainer, Wrapper, FullScreenMessage } from "./Chat.styled";

// FUNCTION
const Chat = () => {
  const { errorScreenDisplayed } = useChat();

  if (errorScreenDisplayed) {
    return (
      <FullScreenMessage>
        <h1>Acceso denegado</h1>
        <p>Contacte con un administrador</p>
      </FullScreenMessage>
    );
  }

  return (
    <ChatContainer>
      <Wrapper>
        <Header />
        <Messages />
        <Footer />
      </Wrapper>
    </ChatContainer>
  );
};

export default Chat;
