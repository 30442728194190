const API_URL = `${process.env.REACT_APP_API_URL}`;

export const getUserInfoUrl = (userId) => `${API_URL}/usuarios/${userId}`;

export const getUserCreditsUrl = (userId) =>
  `${API_URL}/usuarios/${userId}/creditos`;

export const getRoleInfoUrl = (roleId) => `${API_URL}/roles/${roleId}`;

export const getChatIdUrl = (assistantId) =>
  `${API_URL}/asistentes/${assistantId}/chats`;

export const getQuerieUrl = (assitantId, chatId) =>
  `${API_URL}/asistentes/${assitantId}/chats/${chatId}/consultas`;

export const getDeleteChatUrl = (assitantId, chatId) =>
  `${API_URL}/asistentes/${assitantId}/chats/${chatId}`;

export const getParameterFrequencyUrl = () => `${API_URL}/parametros/frequency`;
