import styled, { keyframes } from "styled-components";
import { colors } from "../../../../utils/stylesUtil";
import { ReactComponent as RobotImg } from "../../../../images/robot.svg";

const spin = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;

const fadeInUp = keyframes`
  0% {
    opacity: 0;
    transform: translateY(20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
`;

export const WrapperSpinner = styled.div`
  display: flex;
  justify-content: center;
`;

export const Spinner = styled.div`
  border: 4px solid ${colors.darkGreySemiTransparent};
  border-top: 4px solid ${colors.darkGrey};
  border-radius: 50%;
  width: 20px;
  height: 20px;
  animation: ${spin} 0.7s linear infinite;
`;

export const Wrapper = styled.div`
  flex: 1;
  padding: 30px 25px 10px 25px;
  overflow-y: auto;
  background-color: white;
`;

export const WrapperMessage = styled.div`
  display: flex;
  flex-direction: column;
  align-items: ${(props) => (props.role === "user" ? "flex-end" : "normal")};

  > div {
    position: relative;
    margin: 3px 0 20px 0;
    padding: 12px 20px;
    width: fit-content;
    max-width: 70%;
    color: ${(props) => (props.role === "user" ? "white" : "black")};
    font-weight: 500;
    background-color: ${(props) =>
      props.role === "error"
        ? colors.error
        : props.role === "assistant"
          ? colors.mediumGrey
          : colors.blue};

    border-radius: ${(props) =>
      props.role === "user" ? "20px 0 20px 20px" : "0 20px 20px 20px"};
    opacity: 1;
    animation: ${fadeInUp} 0.5s ease forwards;
    line-height: 1.3rem;
  }
`;

export const RobotIcon = styled(RobotImg)`
  position: absolute;
  top: -20px;
  left: -15px;
  height: 2rem;
  z-index: 10;
`;
