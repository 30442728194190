import styled from "styled-components";
import { colors } from "../../../../utils/stylesUtil";

export const Wrapper = styled.footer`
  display: flex;
  background-color: white;
  border-top: 1px solid ${colors.mediumGrey};
  padding: 10px;
  text-align: center;
  background-color: ${colors.lightGrey};

  input {
    width: 100%;
    padding: 10px;
    font-size: 1em;
    border: none;

    &:focus {
      outline: none;
    }
  }

  button {
    margin-left: 10px;
    padding: 7px 8px 7px 13px;
    font-size: 2rem;
    border-radius: 100%;
    border: none;
    color: black;
    background-color: ${colors.mediumGrey};

    &:disabled {
      opacity: 0.3;
    }
  }
`;
