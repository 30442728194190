export function formatDate(createdAt) {
  const date = new Date(createdAt);
  const day = String(date.getUTCDate()).padStart(2, "0");
  const month = String(date.getUTCMonth() + 1).padStart(2, "0");
  const year = date.getUTCFullYear();

  return `${day}-${month}-${year}`;
}

export function formatCreditsRenewal(date, param) {
  const newDate = new Date(date);

  const monthsMap = {
    m: 1,
    b: 2,
    t: 3,
  };

  const monthsToAdd = monthsMap[param] || 0;

  if (monthsToAdd === 0) {
    console.log("No valid param");
    return null;
  }

  newDate.setMonth(newDate.getMonth() + monthsToAdd);

  return formatDate(newDate);
}
